import React, { useEffect, useState } from 'react';
import { MenuItem, PageHeader } from '../../../components/PageHeader';
import { Button, Timeline, Typography, message, TimelineItemProps } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { api } from '../../../services/api';
import { useParams } from 'react-router-dom';
import { IServiceOrder, IServiceOrderHistory } from '../../../types';
import { ServiceOrderStatusTag } from '../components/ServiceOrderStatusTag';
import { Content } from '../../../components/Content';
import { useAuth } from '../../../contexts/AuthContext';

export default function ServiceOrder() {
  const [order, setOrder] = useState<IServiceOrder>({} as IServiceOrder);
  const [load, setLoad] = useState(true);

  const { user } = useAuth();
  console.log(order)

  const params = useParams();
  const { Text } = Typography;

  useEffect(() => {
    api
      .get(`service-orders/${params.id}`)
      .then((data: any) => {
        console.log(data.data);
        setOrder(data.data);
      })
      .catch((error: any) => {
        message.error('Algo inesperado ocorreu!');
        console.log(error);
      });
  }, [params.id, load]);

  function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const colors = {
    CANCELLED: { name: 'Cancelada', color: 'red' },
    REQUESTED: { name: 'Solicitada', color: 'lime' },
    WAITING: { name: 'AGUARDANDO', color: 'yellow' },
    PENDING: { name: 'Pendente', color: 'orange' },
    OPEN: { name: 'Aberta', color: 'green' },
    REOPENED: { name: 'Reaberta', color: 'cyan' },
    EXECUTION: { name: 'em Execução', color: 'blue' },
    DELAYED: { name: 'Atrasada', color: 'red' },
    FINALIZED: { name: 'Finalizada', color: 'default' },
    VALIDATED: { name: 'Validada', color: 'purple' },
  };

  function formatDateCustom(isoDate: string) {
    const date = new Date(isoDate);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adicione 1 ao mês, pois janeiro é 0
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    const formattedDate = `${day}/${month}/${year} às ${hours}:${minutes}h`;
    return formattedDate;
  }

  const formatHistoryText = (history: IServiceOrderHistory) => {
    return `OS  ${colors[history?.status]?.name} em: ${formatDateCustom(
      history.createdAt,
    )}`;
  };

  const historiesItens = order?.histories?.map((item) => {
    return {
      children: formatHistoryText(item),
      color: colors[item.status]?.color,
    };
  }) as TimelineItemProps[];

  const aproveServiceOrder = (id: string) => {
    api
      .put(`/service-orders/approve/${id}`)
      .then((data: any) => {
        setLoad(!load);
        message.success('Atendimento aprovado com sucesso!');
      })
      .catch((error: any) => {
        console.log(error);
        message.error('Falha ao tentar aprovar o Atendimento!');
      });
  };

  const refuseServiceOrder = (id: string) => {
    api
      .put(`/service-orders/refuse/${id}`)
      .then((data: any) => {
        setLoad(!load);
        message.success('Atendimento cancelado com sucesso!');
      })
      .catch((error: any) => {
        console.log(error);
        message.error('Falha ao tentar cancelar o Atendimento!');
      });
  };

  const OSTypes = {
    CONTRACT: { name: 'CONTRATO' },
    SINGLE: { name: 'SIMPLES' },
    BUDGET: { name: 'ORÇAMENTO' },
  } as any;

  return (
    <>
      <PageHeader
        title={`Ordem de serviço: ${order?.sid}`}
        actions={[
          order?.status === 'WAITING' && user?.profile === 'MANAGER' && (
            <Button
              icon={<CheckCircleOutlined />}
              key={'approve_item'}
              type="primary"
              style={{ marginRight: 12 }}
              onClick={() => aproveServiceOrder(order?.id)}
            >
              Aprovar
            </Button>
          ),
          order?.status === 'WAITING' && user?.profile === 'MANAGER' && (
            <Button
              icon={<CloseCircleOutlined />}
              key={'cancel_item'}
              type="default"
              style={{
                marginRight: 12,
                color: '#8C8C8C',
                borderColor: '#d9d9d9',
              }}
              onClick={() => refuseServiceOrder(order?.id)}
            >
              Cancelar
            </Button>
          ),
        ]}
        menuItems={[getItem('Progresso da OS', '', <MailOutlined />)]}
      >
        <div style={{ display: 'flex', marginTop: 15 }}>
          <Text style={{ fontWeight: 500, minWidth: 124 }}>
            Descrição da OS:
          </Text>
          <Text style={{ fontWeight: 400, maxWidth: 940 }}>
            {' '}
            {order.description}
          </Text>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '275px 275px 335px',
            marginTop: 20,
            gap: 5,
          }}
        >
          <Text style={{ fontWeight: 500 }}>
            Tipo de atendimento:{' '}
            <Text style={{ fontWeight: 400 }}>
              {OSTypes[order.type || '']?.name}
            </Text>
          </Text>
          <Text style={{ fontWeight: 500 }}>
            Local: <Text style={{ fontWeight: 400 }}>{order.local?.name}</Text>
          </Text>
          <Text style={{ fontWeight: 500 }}>
            Previsão de atendimento:{' '}
            <Text style={{ fontWeight: 400 }}>{order.startTo}</Text>
          </Text>
          <Text style={{ fontWeight: 500 }}>
            Cliente:{' '}
            <Text style={{ fontWeight: 400 }}>{order.client?.name}</Text>
          </Text>
          <Text style={{ fontWeight: 500 }}>
            Técnico:
            <Text style={{ fontWeight: 400 }}>
              {
                order?.users?.find((user) => user.profile === 'TECHNICIAN')
                  ?.name
              }
            </Text>
          </Text>
          <Text style={{ fontWeight: 500 }}>
            Status: {<ServiceOrderStatusTag status={order?.status} />}
          </Text>
        </div>
      </PageHeader>
      <Content style={{ marginTop: 24 }}>
        <Text>Linha de Tempo da OS</Text>
      </Content>
      <Content style={{ marginTop: 2 }}>
        <Timeline
          mode="alternate"
          style={{ marginTop: 20 }}
          items={historiesItens}
        />
      </Content>
    </>
  );
}
