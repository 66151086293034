import { FC, useEffect, useState } from 'react';
import 'dayjs/locale/pt-br';

import {
  Button,
  Form,
  App,
  Modal,
  Input,
  Select,
  Upload,
  UploadProps,
} from 'antd';

import { TOKEN_API, api } from '../../../services/api';
import {
  IEquipmentType,
  ILocal,
  IPagination,
  IServiceType,
} from '../../../types';
import { useAuth } from '../../../contexts/AuthContext';
import { InboxOutlined } from '@ant-design/icons';

const { TextArea } = Input;

interface SteperModalProps {
  onClose: () => void;
  onRefreshPage?: () => void;
  isModalOpen: boolean;
}

const getEquipmentTypes = async (page = 1, perPage = 10, q?: any) => {
  return await api.get<IPagination<IEquipmentType[]>>(`/equipment-types`, {
    params: {
      page,
      perPage,
      ...q,
    },
  });
};

const getServiceTypes = async (page = 1, perPage = 10, q?: any) => {
  return await api.get<IPagination<IServiceType[]>>('/service-types', {
    params: {
      page,
      perPage,
      ...q,
    },
  });
};

const getUserLocals = async (clientId?: string, page = 1, perPage = 1000) => {
  return await api.get<ILocal[]>(`/user-locals`, {
    params: {
      page,
      perPage,
      clientId,
    },
  });
};

const SteperModal: FC<SteperModalProps> = ({
  onClose,
  onRefreshPage,
  isModalOpen,
}) => {
  const { message } = App.useApp();
  const { user } = useAuth();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [locals, setLocals] = useState<ILocal[]>([]);
  const [equipmentTypes, setEquipmentTypes] = useState<IEquipmentType[]>([]);
  const [serviceTypes, setServiceTypes] = useState<IServiceType[]>([]);
  const [files, setFiles] = useState<any>();

  useEffect(() => {
    const getData = async () => {
      await getServiceTypes(1, 10).then(({ data }) => {
        setServiceTypes(data.data);
      });
      await getEquipmentTypes(1, 1000).then(({ data }) => {
        setEquipmentTypes(data.data);
      });
      await getUserLocals(user?.clientId, 1, 1000).then(({ data }) => {
        setLocals(data);
      });
    };
    if (isModalOpen) {
      getData();
    }
  }, [form, isModalOpen, user?.clientId]);

  const handleCloseModal = () => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose();
  };

  const handleRefreshPage = () => {
    if (onRefreshPage) onRefreshPage();
  };

  const onSubmit = async () => {
    const values = {
      description: form.getFieldValue('description'),
      serviceTypes: form.getFieldValue('serviceTypes'),
      equipmentTypes: form.getFieldValue('equipmentTypes'),
      localId: form.getFieldValue('localId'),
      files: files,
    };

    setLoading(true);
    await api
      .post(`/service-orders`, values)
      .then(() => {
        message.success(`Ordem de Serviço solicitada com sucesso!`);
        handleCloseModal();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message),
          );
        } else {
          message.error('Algo inesperado ocorreu!');
        }
      })
      .finally(() => {
        setLoading(false);
        handleRefreshPage();
      });
    setLoading(false);
  };

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    action: 'https://new-arcke-api-production.up.railway.app/upload',
    data: {
      folder: 'service_order_files',
    },
    headers: {
      authorization: `Bearer ${localStorage.getItem(TOKEN_API)}`,
    },
    onChange(info) {
      if (info.file.status === 'done') {
        setFiles(info.fileList.map((file) => file.response));
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        if (info.file.response?.errors) {
          info.file.response.errors.forEach((err: any) =>
            message.error(err.message),
          );
        } else {
          message.error(`${info.file.name} Erro ao enviar o arquivo.`);
        }
      }
    },
    accept: 'image/*,.pdf',
    listType: 'picture',
    maxCount: 3,
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Modal
      title={'Solicitação de Ordem de Serviço'}
      open={isModalOpen}
      onCancel={handleCloseModal}
      width={540}
      centered
      footer={
        <div
          style={{
            display: 'flex',
            gap: 10,
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <Button onClick={handleCloseModal} style={{ width: 100 }}>
            Cancelar
          </Button>
          <Button
            type="primary"
            onClick={onSubmit}
            style={{ margin: 0, width: 100 }}
          >
            Solicitar
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="service_order_form_modal"
        // onFinish={next}
        style={{ marginTop: 32 }}
      >
        <Form.Item
          label="Qual o tipo de serviço que deseja solicitar?"
          name="serviceTypes"
          rules={[
            {
              required: true,
              message: 'Por favor selecione o Tipo de serviço!',
            },
          ]}
          colon
          style={{ display: 'block' }}
        >
          <Select
            optionLabelProp="label"
            optionFilterProp="label"
            mode="multiple"
            showSearch
            allowClear
            options={serviceTypes.map((serviceType) => {
              return {
                label: serviceType.name,
                value: serviceType.id,
              };
            })}
          />
        </Form.Item>
        <Form.Item
          label="Para qual tipo de equipamento deseja atendimento?"
          name="equipmentTypes"
          rules={[
            {
              required: true,
              message: 'Por favor selecione o Equipamento!',
            },
          ]}
        >
          <Select
            mode="multiple"
            optionLabelProp="label"
            optionFilterProp="label"
            showSearch
            allowClear
            options={equipmentTypes.map((equipmentType) => {
              return {
                label: equipmentType.name,
                value: equipmentType.id,
              };
            })}
          />
        </Form.Item>
        <Form.Item
          label="Qual o local a ser realizado o serviço?"
          name="localId"
          rules={[
            {
              required: true,
              message: 'Por favor selecione o Local!',
            },
          ]}
        >
          <Select
            optionLabelProp="label"
            optionFilterProp="label"
            showSearch
            allowClear
            options={locals.map((local) => {
              return {
                label: local.name,
                value: local.id,
              };
            })}
          />
        </Form.Item>
        <Form.Item
          label="Qual a descrição do serviço?"
          name="description"
          rules={[
            {
              required: true,
              message: 'Por favor, insira a Descrição do Serviço',
            },
          ]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item label="Anexar arquivo na solicitação?">
          <Form.Item
            name="file"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            noStyle
          >
            <Upload.Dragger name="file" {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click ou arraste o arquivo para essa area de upload
              </p>
              <p className="ant-upload-hint">
                Suporte para upload único ou em massa.
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { SteperModal };
