import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AuthProviderLayout } from '../contexts/AuthContext';

import { Layout } from './layout';
import { RequireAuth } from './requireAuth';

import { AuthRouter } from '../pages/auth/auth.routes';

import { OrderRouter } from '../pages/orders/orders.routes';

import { NoMatch } from './noMatchRoute';

const router = createBrowserRouter([
  {
    element: <AuthProviderLayout />,
    children: [
      AuthRouter,
      {
        element: <RequireAuth />,
        children: [
          {
            element: <Layout />,
            path: '/',
            children: [OrderRouter],
          },
        ],
      },
      {
        path: '*',
        element: <NoMatch to="/" />,
      },
    ],
  },
]);

const Routes = () => {
  return <RouterProvider router={router} />;
};

export { Routes };
