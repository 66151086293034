import { useState, useEffect, useCallback, FC } from 'react';

import { App, Button, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { IMeta, IPagination, IServiceOrder } from '../../../types';
import { Content } from '../../../components/Content';
import { PageHeader } from '../../../components/PageHeader';
import { api } from '../../../services/api';
import { ActionColumn, ActionFunction } from '../../../components/ActionColumn';
import { ServiceOrderStatusTag } from '../components/ServiceOrderStatusTag';
import { formatters } from '../../../utils/formatters';
import { FilterOrderList } from '../components/FilterOrderList';
import { SteperModal } from '../components/SteperModal';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const getServiceOrders = async (page = 1, perPage = 10, q?: any) => {
  return await api.get<IPagination<IServiceOrder[]>>(`/service-orders`, {
    params: {
      page,
      perPage,
      ...q,
    },
  });
};

const Orders: FC = () => {
  const { message } = App.useApp();
  const [orders, setOrders] = useState<IServiceOrder[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState();
  const [load, setLoad] = useState(true);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });
  const navigate = useNavigate();

  const { user } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShowModal = () => {
    setIsModalOpen(true);
  };

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      await getServiceOrders(page, pageSize, filter)
        .then(({ data }) => {
          setOrders(data?.data);
          setMeta(data?.meta);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 403) {
            message.error(
              'Desculpe! no momento você não tem permissão para isso.',
            );
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [message, filter],
  );

  const aproveServiceOrder = (id: string) => {
    api
      .put(`/service-orders/approve/${id}`)
      .then((data: any) => {
        setLoad(!load);
        message.success('Atendimento aprovado com sucesso!');
      })
      .catch((error: any) => {
        console.log(error);
        message.error('Falha ao tentar aprovar o Atendimento!');
      });
  };

  const refuseServiceOrder = (id: string) => {
    api
      .put(`/service-orders/refuse/${id}`)
      .then((data: any) => {
        setLoad(!load);
        message.success('Atendimento cancelado com sucesso!');
      })
      .catch((error: any) => {
        console.log(error);
        message.error('Falha ao tentar cancelar o Atendimento!');
      });
  };

  useEffect(() => {
    handleChangePage(1, meta.per_page);
  }, [load, meta.per_page, handleChangePage]);

  const actions: ActionFunction<IServiceOrder> = (record) => {
    const options = [
      {
        key: '1',
        icon: <SearchOutlined />,
        label: 'Detalhar',
        condition: true,
        onClick: () => navigate(`/orders/${record.id}`),
      },
      {
        key: '2',
        icon: <CheckCircleOutlined />,
        label: 'Aprovar',
        condition: user?.profile === 'MANAGER' && record?.status === 'WAITING',
        onClick: () => aproveServiceOrder(record.id),
      },
      {
        key: '3',
        icon: <CloseCircleOutlined />,
        label: 'Cancelar',
        condition: user?.profile === 'MANAGER' && record?.status === 'WAITING',
        onClick: () => refuseServiceOrder(record.id),
      },
    ];

    const filter = [];

    for (let option of options) {
      if (option.condition) {
        filter.push(option);
      }
    }

    return filter;
  };

  const OSTypes = {
    CONTRACT: { name: 'CONTRATO' },
    SINGLE: { name: 'SIMPLES' },
    BUDGET: { name: 'ORÇAMENTO' },
  } as any;

  const columns: ColumnsType<IServiceOrder> = [
    {
      title: 'ID',
      dataIndex: 'sid',
      key: 'sid',
      width: 80,
      render: (value) => (
        <Typography style={{ color: '#4ECB73' }}>
          {value?.toString().padStart(4, '0')}
        </Typography>
      ),
    },
    {
      title: 'Descrição da OS',
      dataIndex: 'description',
      key: 'description',
      render: (text) => text,
      ellipsis: true,
    },
    {
      title: 'Tipo de Atendimento',
      dataIndex: 'type',
      key: 'type',
      render: (type) => OSTypes[type]?.name || 'N/A',
      ellipsis: true,
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
      render: (client) => client?.name || 'N/A',
    },
    {
      title: 'Local',
      dataIndex: 'local',
      key: 'local',
      render: (local) => local?.name || 'N/A',
    },
    {
      title: 'Técnico',
      dataIndex: 'users',
      key: 'users',
      render: (users: IServiceOrder['users']) => {
        return users.find((user) => user.profile === 'TECHNICIAN')?.name;
      },
      ellipsis: true,
    },
    {
      title: 'Prévia de Atendimento:',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => formatters.simpleDate(date),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (status) => <ServiceOrderStatusTag status={status} />,
    },
    ActionColumn(actions),
  ];

  const handleFilterData = (values: any) => {
    setFilter(values);
  };

  return (
    <>
      <PageHeader
        title="Atendimentos"
        backButton={false}
        actions={[
          <Button
            icon={<PlusOutlined />}
            key={'create_item'}
            onClick={handleShowModal}
            type="primary"
          >
            Solicitar Atendimento
          </Button>,
        ]}
      />
      <SteperModal
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onRefreshPage={() => setLoad(!load)}
      />
      <Content margin>
        <FilterOrderList onSubmit={handleFilterData} />
      </Content>
      <Content margin>
        <Table
          pagination={{
            position: ['bottomRight'],
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20],
            current: meta.current_page,
            pageSize: meta.per_page,
            total: meta.total,
            onChange: handleChangePage,
            size: 'default',
          }}
          size="small"
          loading={loading}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={orders}
        />
      </Content>
    </>
  );
};

export { Orders };
