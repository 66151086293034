import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Dropdown, Menu, MenuProps, theme } from 'antd';
import { Header as AntHeader } from 'antd/es/layout/layout';
import {
  ToolOutlined,
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
} from '@ant-design/icons';

import Logo from '../../assets/logo-arcke.png';
import { useAuth } from '../../contexts/AuthContext';
import { api } from '../../services/api';
import { IUser } from '../../types';

import ProfileModal from './components/ProfileModal';
import EditProfileModal from './components/EditProfileModal';
import EditPasswordModal from './components/EditPasswordModal';

const items: MenuProps['items'] = [
  {
    label: 'Atendimento',
    key: 'orders',
    icon: <ToolOutlined />,
  },
];

const Header = () => {
  const {
    token: { colorBgBase, colorBgContainer, boxShadowTertiary },
  } = theme.useToken();
  const [modalProfileIsOpen, setModalProfileIsOpen] = useState(false);
  const [modalEditProfileIsOpen, setModalEditProfileIsOpen] = useState(false);
  const [modalEditPasswordIsOpen, setModalEditPasswordIsOpen] = useState(false);
  const [userCurrent, setUserCurrent] = useState<IUser | null>();

  const { signOut, user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const selectedKey = location.pathname.includes('/orders') ? ['orders'] : [];

  const onClick: MenuProps['onClick'] = (e) => {
    navigate(e.key);
  };

  useEffect(() => {
    api.get('/me').then((data) => {
      setUserCurrent(data.data);
    });
  }, [modalEditProfileIsOpen]);

  const userMenu: MenuProps['items'] = [
    {
      key: '1',
      label: 'Meu Perfil',
      icon: <SettingOutlined />,
      onClick: () => setModalProfileIsOpen(true),
      style: {
        color: '#4ECB73',
      },
    },
    {
      key: '2',
      label: 'Sair',
      icon: <LogoutOutlined />,
      onClick: signOut,
      danger: true,
    },
  ];

  return (
    <AntHeader
      style={{
        backgroundColor: colorBgContainer,
        padding: '0px',
        paddingLeft: '16px',
        height: '48px',
        lineHeight: '48px',
        width: '100%',
        boxShadow: boxShadowTertiary,
        display: 'flex',
        justifyContent: 'space-between',
        position: 'fixed',
        zIndex: 1,
      }}
    >
      <style type="text/css">{`
        body {
            background-color: ${colorBgBase};
        }
    `}</style>
      <div style={{ width: '100%' }}>
        <div className="logo">
          <img
            src={Logo}
            alt="Arke-logo"
            style={{ objectFit: 'cover', width: '120px' }}
          />
        </div>
        <Menu
          onClick={onClick}
          mode="horizontal"
          items={items}
          selectedKeys={selectedKey}
        />
      </div>
      <div style={{ paddingRight: '16px' }}>
        <Dropdown
          trigger={['click']}
          menu={{ items: userMenu }}
          placement="bottomLeft"
        >
          <Avatar
            icon={<UserOutlined />}
            src={`${process.env.REACT_APP_API}${userCurrent?.account?.image}`}
            style={{ cursor: 'pointer' }}
          />
        </Dropdown>
      </div>
      <ProfileModal
        isModalOpen={modalProfileIsOpen}
        onClose={() => setModalProfileIsOpen(false)}
        onPressConfirmButton={() => setModalEditProfileIsOpen(true)}
        onPressEditPasswordButton={() => setModalEditPasswordIsOpen(true)}
        user={userCurrent}
      />
      <EditProfileModal
        isModalOpen={modalEditProfileIsOpen}
        onClose={() => {
          setModalEditProfileIsOpen(false);
        }}
        user={user}
      />
      <EditPasswordModal
        isModalOpen={modalEditPasswordIsOpen}
        onClose={() => setModalEditPasswordIsOpen(false)}
      />
    </AntHeader>
  );
};

export { Header };
