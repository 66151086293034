import { FC, useEffect, useState } from 'react';
import { Col, Form, Input, Select } from 'antd';
import { ExpandableFilter } from '../../../components/ExpandableFilter';
import { api } from '../../../services/api';
import { ILocal } from '../../../types';
import { useAuth } from '../../../contexts/AuthContext';

interface FilterOrderListProps {
  onSubmit?: any;
}

interface FilterItemFormInstance {
  id?: string;
  status?: string[];
  isConsumible?: boolean;
}

const Status = [
  { name: 'REQUESTED', label: 'REQUISITADO' },
  { name: 'PENDING', label: 'PENDENTE' },
  { name: 'OPEN', label: 'ABERTO' },
  { name: 'REOPENED', label: 'REABERTO' },
  { name: 'WAITING', label: 'AGUARDANDO' },
  { name: 'EXECUTION', label: 'EXECUÇÃO' },
  { name: 'DELAYED', label: 'ATRASADO' },
  { name: 'FINALIZED', label: 'FINALIZADO' },
  { name: 'VALIDATED', label: 'VALIDADO' },
];

const getUserLocals = async (clientId?: string, page = 1, perPage = 1000) => {
  return await api.get<ILocal[]>(`/user-locals`, {
    params: {
      page,
      perPage,
      clientId,
    },
  });
};

const FilterOrderList: FC<FilterOrderListProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<FilterItemFormInstance>();
  const { user } = useAuth();
  const [locals, setLocals] = useState<ILocal[]>([]);

  useEffect(() => {
    const getData = async () => {
      await getUserLocals(user?.clientId, 1, 1000).then(({ data }) => {
        setLocals(data);
      });
    };
    getData();
  }, [user?.clientId]);

  const fields = [
    <Col span={7} key={1}>
      <Form.Item name="id" label="ID:" style={{ margin: 0 }}>
        <Input allowClear placeholder="Insira um ID" />
      </Form.Item>
    </Col>,
    <Col span={7} key={2}>
      <Form.Item name="status" label="Status" style={{ margin: 0 }}>
        <Select
          placeholder="Escolha o status"
          allowClear
          showSearch
          optionLabelProp="label"
          optionFilterProp="label"
          mode="multiple"
          options={Status.map((status) => {
            return {
              value: status.name,
              label: status.label,
            };
          })}
        />
      </Form.Item>
    </Col>,
    <Col span={7} key={3}>
      <Form.Item name="localId" label="Local" style={{ margin: 0 }}>
        <Select
          placeholder="Escolha o Local"
          allowClear
          showSearch
          optionLabelProp="label"
          optionFilterProp="label"
          options={locals.map((local) => {
            return {
              label: local.name,
              value: local.id,
            };
          })}
        />
      </Form.Item>
    </Col>,
  ];

  const onFinish = (values: FilterItemFormInstance) => {
    if (onSubmit) {
      onSubmit({ ...values, status: values.status?.toString() });
    }
  };

  const onClear = () => {
    onSubmit();
  };

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { FilterOrderList };
