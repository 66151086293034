import { ReactNode, FC, CSSProperties } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Menu, MenuProps, Space, theme, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { Content } from '../Content';

const { Title } = Typography;

export type MenuItem = Required<MenuProps>['items'][number];

interface PageHeaderProps {
  title?: string;
  backButton?: boolean;
  children?: ReactNode;
  actions?: ReactNode[];
  footer?: ReactNode[];
  menuItems?: MenuItem[];
  defaultSelectedKeys?: string[];
  style?: CSSProperties;
}

const PageHeader: FC<PageHeaderProps> = ({
  title,
  backButton = true,
  children,
  footer,
  actions,
  menuItems,
  defaultSelectedKeys,
  style,
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    token: { boxShadowTertiary },
  } = theme.useToken();

  const onClick: MenuProps['onClick'] = ({ key }) => {
    navigate(key);
  };

  return (
    <Content
      fullScreenWidth
      style={{
        boxShadow: boxShadowTertiary,
        ...style,
        padding: '24px 24px 0',
      }}
    >
      <Space
        align="center"
        direction="horizontal"
        style={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Space align="start">
          {backButton ? (
            <Button
              type="text"
              onClick={() => {
                navigate(-1);
              }}
              size="large"
              icon={<ArrowLeftOutlined />}
            />
          ) : null}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '10px',
            }}
          >
            <Typography.Text style={{ fontSize: 12 }}>{`Central do Cliente / ${
              params.id ? title?.replace(':', ' / ') : title
            }
            `}</Typography.Text>
            <Title level={3} style={{ margin: '10px 0 0' }}>
              {title}
            </Title>
          </div>
        </Space>
        <div style={{ display: 'flex' }}>{actions?.map((item) => item)}</div>
      </Space>
      {children}
      <Space
        direction="horizontal"
        style={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Menu
          key={'menu'}
          mode="horizontal"
          style={{ minWidth: '35rem', marginTop: 14 }}
          onClick={onClick}
          defaultSelectedKeys={defaultSelectedKeys}
          items={menuItems}
        />
        <div style={{ display: 'flex' }}>{footer?.map((item) => item)}</div>
      </Space>
    </Content>
  );
};

export { PageHeader };
