import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AxiosResponse } from 'axios';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { api, STORAGE_USER, TOKEN_API } from '../services/api';

import { IUser } from '../types';
import { Loading } from '../components/Loading';

type LoginResponse = {
  user: IUser;
  token: {
    type: string;
    token: string;
  };
};

type SignInCredentials = {
  username: string;
  password: string;
};

type AuthContextData = {
  user: IUser | null;
  isAuthenticated: boolean;
  signIn(
    credentials: SignInCredentials,
  ): Promise<{ username: string; password: string } | void | undefined>;
  signOut(): Promise<void>;
  refreshUserData: (data: IUser) => void;
};

type AuthProviderProps = {
  children: ReactNode;
};

const AuthContext = createContext({} as AuthContextData);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(true);
  const isAuthenticated = !!user;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const loadStorageData = async () => {
      const storageToken = localStorage.getItem(TOKEN_API);
      if (storageToken) {
        api.defaults.headers.common.Authorization = `Bearer ${storageToken}`;
        await api
          .get('/me')
          .then(({ data }: AxiosResponse<IUser>) => {
            setUser(data);
            localStorage.setItem(STORAGE_USER, JSON.stringify(data));
          })
          .finally(() => {
            setLoading(false);
          });
      }
      setLoading(false);
    };
    loadStorageData();
  }, []);

  const signIn = async ({ username, password }: SignInCredentials) => {
    return await api
      .post('/login', {
        username: username.trim().toLowerCase(),
        password: password.trim(),
      })
      .then(({ data }: AxiosResponse<LoginResponse>) => {
        const { token, user } = data;
        setUser(user);
        api.defaults.headers.common.Authorization = `Bearer ${token.token}`;
        api.defaults.headers.Authorization = `Bearer ${token.token}`;

        localStorage.setItem(STORAGE_USER, JSON.stringify(user));
        localStorage.setItem(TOKEN_API, token.token);
        const origin = location.state?.from?.pathname || '/orders';
        navigate(origin, { replace: true });
        return;
      })
      .catch((error) => {
        console.log(error);

        throw new Error();
      });
  };

  const signOut = async () => {
    setLoading(true);
    await api.post('/logout').finally(() => {
      setUser(null);
      localStorage.removeItem(STORAGE_USER);
      localStorage.removeItem(TOKEN_API);
      navigate('/login');
      setLoading(false);
    });
  };

  function refreshUserData(data: IUser) {
    setUser({ ...user, ...data });
  }

  return (
    <AuthContext.Provider
      value={{ user, isAuthenticated, signIn, signOut, refreshUserData }}
    >
      {loading ? <Loading /> : children}
    </AuthContext.Provider>
  );
};

const AuthProviderLayout = () => (
  <AuthProvider>
    <Outlet />
  </AuthProvider>
);

const useAuth = () => {
  return useContext(AuthContext);
};

export { useAuth, AuthProvider, AuthProviderLayout, AuthContext };
